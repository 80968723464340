<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="设备名称" prop="equipment_name" style="width: 25%">
              <a-input v-model="queryParams.equipment_name"></a-input>
<!--              <a-select v-model="queryParams.equipment_name" show-search :filter-option="filterOption">-->
<!--                <a-select-option v-for="(item, index) in equipmentNameList" :key="index" :value="item.equipment_name">{{item.equipment_name}}</a-select-option>-->
<!--              </a-select>-->
            </a-form-model-item>
            <a-form-model-item label="计划年份" prop="year" style="width: 25%">
              <a-select v-model="queryParams.year">
                <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="维保单位" prop="maintenance_unit" style="width: 25%">
                <a-select v-model="queryParams.maintenance_unit" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in maintenanceUnitList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="equipment_type" style="width: 25%">
                <a-select v-model="queryParams.equipment_type" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备编号" prop="equipment_num" style="width: 25%">
                <a-input v-model="queryParams.equipment_num"></a-input>
<!--                <a-select v-model="queryParams.equipment_num" show-search :filter-option="filterOption">-->
<!--                  <a-select-option v-for="(item, index) in equipmentNumList" :key="index" :value="item.equipment_num">{{item.equipment_num}}</a-select-option>-->
<!--                </a-select>-->
              </a-form-model-item>
              <a-form-model-item label="安装位置" prop="address" style="width: 25%">
                <a-select :disabled="disabled" v-model="queryParams.address" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in addressList" :key="index" :value="item.address">{{item.address}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="防火分区" prop="fire_compartment" style="width: 25%">
                <a-select :disabled="disabled" v-model="queryParams.fire_compartment" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in fireCompartmentList" :key="index" :value="item.fire_compartment">{{item.fire_compartment}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="保养类型" prop="maintenance_schedule_type" style="width: 25%">
                <a-select  v-model.trim="queryParams.maintenance_schedule_type" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item,index) in equipmentMaintenanceItemList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保形式" prop="maintenance_form" style="width: 25%">
                <a-select v-model="queryParams.maintenance_form">
                  <a-select-option value="自保">自保</a-select-option>
                  <a-select-option value="外委">外委</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="任务名称" prop="task_name" style="width: 25%">
                <a-select v-model="queryParams.task_name" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in taskNameList" :key="index" :value="item.task_name">{{item.task_name}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="计划状态" prop="plan_status" style="width: 25%">
                <a-select v-model="queryParams.plan_status">
                  <a-select-option value="1">运行</a-select-option>
                  <a-select-option value="0">停止</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('复制')" @click="yearPlanCopy" style="margin-right: 10px"><a-icon type="snippets"></a-icon>年度计划复制</a-button>
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="maintenance_schedule_id" :loading="tableLoading" :scroll="{ x: 2800}" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
          <span slot="plan_status" slot-scope="value">
            <a-tag :color="value==1?'green':'red'">{{value==1?'运行':'停止'}}</a-tag>
          </span>
          <span slot="maintenance_schedule_type" slot-scope="value,record">{{equipmentMaintenanceItemList[value-1]?equipmentMaintenanceItemList[value-1].dicvalue:''}}</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.maintenance_schedule_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.maintenance_schedule_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('复制')" :key="'copy-'+record.maintenance_schedule_id">复制</a-menu-item>
                <a-menu-item v-if="btnList.includes('粘贴')" :key="'paste-'+record.maintenance_schedule_id">粘贴</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.maintenance_schedule_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="planModalVisible" title="年度计划复制" :width="400" :centered="true" @cancel="planModalVisible=false">
      <template slot="footer">
        <a-button @click="planModalVisible=false">关闭</a-button>
        <a-button @click="generateYearPlan" type="primary">保存</a-button>
      </template>
      <a-form-model ref="modalForm" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="计划来源年份" prop="sourceYear">
          <a-select v-model="formData.sourceYear" placeholder="请选择">
            <a-select-option :value="moment().year()-1">{{moment().year()-1}}</a-select-option>
            <a-select-option :value="moment().year()">{{moment().year()}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="目标年份" prop="targetYear">
          <a-select v-model="formData.targetYear" placeholder="请选择">
            <a-select-option :value="moment().year()">{{moment().year()}}</a-select-option>
            <a-select-option :value="moment().year()+1">{{moment().year()+1}}</a-select-option>
            <a-select-option :value="moment().year()+2">{{moment().year()+2}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData(false)"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/facilityAndEquipment/maintenanceManagement/maintenance/plan/addOrEditModal";
import {
  deleteMaintenanceSchedule, exportMaintenanceScheduleExcel, generateYearPlan, getMaintenanceScheduleConditionList,
  getMaintenanceScheduleListByCondition, getMaintenanceUnitListByCondition,
} from "A/maintenance";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {getUserdepListByCondition} from "A/jcgn";
export default {
  name: "maintenancePlan",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      disabled:true,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      maintenanceUnitList:[],
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        equipment_type:'',
        equipment_num:'',
        equipment_name:'',
        task_name:'',
        contract_num:'',
        maintenance_unit_id:'',
        fire_compartment:'',
        maintenance_unit:'',
        address:'',
        year:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          width: 150,
        },
        {
          title: '安装位置',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '防火分区',
          dataIndex: 'fire_compartment',
          key: 'fire_compartment',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' },
          width: 75,
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_num' },
          width: 170,
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
          width: 250,
        },
        {
          title: '维保形式',
          dataIndex: 'maintenance_form',
          key: 'maintenance_form',
          ellipsis: true,
          width: 75,
        },
        {
          title: '任务名称',
          dataIndex: 'task_name',
          key: 'task_name',
          ellipsis: true,
        },
        {
          title: '合同编号',
          dataIndex: 'contract_num',
          key: 'contract_num',
          ellipsis: true,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenance_unit_name',
          key: 'maintenance_unit_name',
          ellipsis: true,
        },
        {
          title: '维保组',
          dataIndex: 'maintenance_group_name',
          key: 'maintenance_group_name',
          ellipsis: true,
        },
        {
          title: '保养类型',
          dataIndex: 'maintenance_schedule_type',
          key: 'maintenance_schedule_type',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenance_schedule_type' },
          width: 140,
        },
        {
          title: '当前状态',
          dataIndex: 'plan_status',
          key: 'plan_status',
          ellipsis: true,
          width: 75,
          scopedSlots: { customRender: 'plan_status' },
        },
        {
          title: '一月',
          dataIndex: 'month_one',
          key: 'month_one',
          ellipsis: true,
          width: 100,
        },
        {
          title: '二月',
          dataIndex: 'month_two',
          key: 'month_two',
          ellipsis: true,
          width: 100,
        },
        {
          title: '三月',
          dataIndex: 'month_three',
          key: 'month_three',
          ellipsis: true,
          width: 100,
        },
        {
          title: '四月',
          dataIndex: 'month_four',
          key: 'month_four',
          ellipsis: true,
          width: 100,
        },
        {
          title: '五月',
          dataIndex: 'month_five',
          key: 'month_five',
          ellipsis: true,
          width: 100,
        },
        {
          title: '六月',
          dataIndex: 'month_six',
          key: 'month_six',
          ellipsis: true,
          width: 100,
        },
        {
          title: '七月',
          dataIndex: 'month_seven',
          key: 'month_seven',
          ellipsis: true,
          width: 100,
        },
        {
          title: '八月',
          dataIndex: 'month_eight',
          key: 'month_eight',
          ellipsis: true,
          width: 100,
        },
        {
          title: '九月',
          dataIndex: 'month_nine',
          key: 'month_nine',
          ellipsis: true,
          width: 100,
        },
        {
          title: '十月',
          dataIndex: 'month_ten',
          key: 'month_ten',
          ellipsis: true,
          width: 100,
        },
        {
          title: '十一月',
          dataIndex: 'month_eleven',
          key: 'month_eleven',
          ellipsis: true,
          width: 100,
        },
        {
          title: '十二月',
          dataIndex: 'month_twelve',
          key: 'month_twelve',
          ellipsis: true,
          width: 100,
        },
        {
          title: '计划年份',
          dataIndex: 'year',
          key: 'year',
          ellipsis: true,
          width: 75,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 50
        },
      ],
      deviceCodeList:[],
      addressList:[],
      equipmentNameList:[],
      equipmentNumList:[],
      taskNameList:[],
      fireCompartmentList:[],
      userdepidCascaderSelected: [],
      equipmentMaintenanceItemList: [],
      copyData:{},
      yearOptions: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      monitorpointList:[],
      formData:{
        monitorpointnum:'',
        sourceYear:'',
        targetYear:'',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        sourceYear: [{required: true, message: '请选择计划来源年份'}],
        targetYear: [{required: true, message: '请选择目标年份'}],
      },
      planModalVisible: false,
      planModalShowType: '',
      planModalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "保养计划"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"保养管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
        getUserdepListByCondition({userdepid:this.queryParams.userdepsid}).then(res=>{
          if(res&&res.returncode==='0'){
            if(res.item[0].levelid==='15'){
              this.getQueryCondition()
              this.disabled=false
            }
          }
        })
      }else {
        this.queryParams.userdepsid = '';
        this.disabled=true
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getYearOptions();
    this.getMonitorPointNameList()
    this.getTableData()
    this.getQueryCondition();
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentMaintenanceItemList = dictionary.equipmentMaintenanceItemList
    }
  },
  methods:{
    getQueryCondition(){
      getMaintenanceScheduleConditionList({userdepsid:this.queryParams.userdepsid}).then(res=>{
        if(res && res.returncode == '0') {
          this.addressList=res.addressList
          this.equipmentNameList=res.equipmentNameList
          this.equipmentNumList=res.equipmentNumList
          this.taskNameList=res.taskNameList
          this.fireCompartmentList=res.fireCompartmentList
        }
      })
    },
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
      getMaintenanceUnitListByCondition({userdepsid:this.userInfo.userdepid}).then(res=> {
        if (res && res.returncode == '0') {
          this.maintenanceUnitList = res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMaintenanceScheduleListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.disabled=true
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.deviceCodeList = dictionary.deviceCodeList
      }
      this.getYearOptions()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let maintenance_schedule_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenance_schedule_id', maintenance_schedule_id);
      if(type == 'delete') {
        this.deleteConfirm(maintenance_schedule_id, record)
      }else if(type == 'copy'){
        this.copyData=record
        this.$message.success("数据已经复制完成")
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type == 'paste'){
        if(this.copyData.month_one){
          this.modalDetailData=record
          this.modalDetailData.plan_status=1
          this.modalDetailData.month_one=this.copyData.month_one
          this.modalDetailData.month_two=this.copyData.month_two
          this.modalDetailData.month_three=this.copyData.month_three
          this.modalDetailData.month_four=this.copyData.month_four
          this.modalDetailData.month_five=this.copyData.month_five
          this.modalDetailData.month_six=this.copyData.month_six
          this.modalDetailData.month_seven=this.copyData.month_seven
          this.modalDetailData.month_eight=this.copyData.month_eight
          this.modalDetailData.month_nine=this.copyData.month_nine
          this.modalDetailData.month_ten=this.copyData.month_ten
          this.modalDetailData.month_eleven=this.copyData.month_eleven
          this.modalDetailData.month_twelve=this.copyData.month_twelve
          this.modalShowType=type
          this.modalVisible=true
        }else{
          this.$message.warning("您未复制任何数据，请您复制后再进行粘贴操作")
        }
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    planModal(){
      this.planModalVisible=true
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEquipDirectories()
      }).catch(()=>{
      });
    },
    exportEquipDirectories(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportMaintenanceScheduleExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.maintenance_schedule_id);
      }).catch(()=>{

      }) ;
    },
    delete(maintenance_schedule_id) {
      this.showLoading();
      if(maintenance_schedule_id) {
        let params = {
          maintenance_schedule_id
        };
        deleteMaintenanceSchedule(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let lastYear = moment().year()-1;
      for(let i=1; i>-1; i--) {
        this.yearOptions.push(currentYear+i+'');
      }
      this.yearOptions.push(lastYear+'')
      this.queryParams.year=currentYear+''
    },
    yearPlanCopy(){
      this.planModalVisible=true
    },
    generateYearPlan(){
      this.$refs.modalForm.validate(valid => {
        if (valid) {
          generateYearPlan({...this.formData})
          this.planModalVisible=false
          this.$message.success("内容已提交后台，正在处理中，请稍后再进行查看")
          this.formData={
            monitorpointnum:'',
            sourceYear:'',
            targetYear:'',
          }
        }
      })
    },
  },
}
</script>
<style>
</style>